import React from 'react';
import './Divider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

function Divider() {
  return (
    <div className={'divider'}>
      <div className={'divider-line'} />
      <div className={'divider-heart'}><FontAwesomeIcon icon={faHeart} /></div>
      <div className={'divider-line'} />
    </div>
  )
}

export default Divider;
