import React from 'react';
import './Header.scss';
import Logo from "../media/full-logo.png";

function Header() {
  return (
    <header>
      <img src={Logo} id='logo' className='img-fluid' alt='logo' />
    </header>
  )
}

export default Header;
