import React from "react";
import ReactPlayer from "react-player";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Videos.scss';

function Videos() {
  let settings = {
    youtube: {
      playerVars: {
        showinfo: 0,
        modestBranding: 1,
        fs: 1,
        controls: 1
      }
    },
  }

  return (
    <>
      <Tabs>
        <TabList>
          <Tab title={'Kids'}>Kids</Tab>
          <Tab title={'TrendsABC'}>Trends A-B-C</Tab>
          <Tab title={'Adults'}>Adults</Tab>
          <Tab title={'Freestyle'}>Freestyle</Tab>
        </TabList>

        <TabPanel>
          <ReactPlayer url="https://youtu.be/Q6fz0353f2g" className='video-player' config={settings} />
          <ReactPlayer url="https://youtu.be/Hy-zdOxWmhs" className='video-player' config={settings} />
          <ReactPlayer url="https://youtu.be/XmLrhqijqCA" className='video-player' config={settings} />
          <ReactPlayer url="https://youtu.be/GVBjTNyFuy8" className='video-player' config={settings} />
          <ReactPlayer url="https://youtu.be/5_j8VzMZv2Q" className='video-player' config={settings} />
        </TabPanel>
        <TabPanel>
          <ReactPlayer url="https://youtu.be/CwgFV80kI1Q" className='video-player' config={settings} />
          <ReactPlayer url="https://youtu.be/uMp38FOETrg" className='video-player' config={settings} />
          <ReactPlayer url="https://youtu.be/j71Wga4Y8ac" className='video-player' config={settings} />
        </TabPanel>
        <TabPanel>
          <ReactPlayer url="https://youtu.be/65Mx7_phkDs" className='video-player' config={settings} />
          <ReactPlayer url="https://youtu.be/Jy5LRrOp7Us" className='video-player' config={settings} />
        </TabPanel>
        <TabPanel>
          <ReactPlayer url="https://youtu.be/XMZDIs1Nh0A" className='video-player' config={settings} />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Videos;
