import React from 'react';
import './Socials.scss';
import { faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Socials() {
  return (
    <div id={'socials'}>
      <p>Neem ook zeker eens een kijkje op onze andere kanalen</p>

      <div className={'social-icons'}>
        <a href={'https://www.facebook.com/KG-Trends-100486928462376'} target={'_blank'}>
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a href={'https://www.krachtengeduld.be'} target={'_blank'}>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
               width="2377.000000pt" height="2305.000000pt" viewBox="0 0 2377.000000 2305.000000"
               preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,2305.000000) scale(0.100000,-0.100000)">
              <path d="M6990 21484 c-143 -62 -221 -90 -293 -105 -218 -44 -320 -119 -363
-266 -14 -48 -13 -67 10 -265 210 -1768 336 -3728 431 -6733 l15 -479 26 -27
c66 -69 239 -104 464 -94 374 16 559 66 657 178 58 66 61 100 24 277 -18 85
-41 232 -51 325 -10 94 -35 294 -54 445 -39 304 -52 435 -62 615 -6 111 -5
124 16 173 27 63 79 112 119 112 56 0 151 -87 263 -242 61 -83 84 -105 167
-161 230 -157 440 -343 766 -683 366 -381 721 -733 838 -829 160 -132 335
-255 363 -255 132 0 554 155 638 234 25 24 28 32 22 67 -25 157 -104 243 -341
369 l-159 85 -301 300 c-568 568 -1161 1103 -1838 1660 l-207 170 0 46 c0 110
168 424 351 654 178 226 480 494 758 673 66 43 110 81 159 138 206 242 383
398 872 769 246 187 414 321 490 392 33 30 114 75 280 156 129 63 271 138 315
167 147 96 262 233 310 370 13 38 13 47 -6 103 -61 182 -270 480 -352 501 -48
12 -333 -142 -647 -348 -361 -238 -649 -468 -1325 -1061 -382 -335 -695 -601
-814 -690 -53 -40 -124 -131 -291 -370 -127 -181 -224 -309 -318 -418 -87
-100 -214 -217 -236 -217 -20 0 -54 42 -73 90 -24 64 -20 243 11 565 32 324
33 440 2 675 -96 730 -187 1715 -247 2685 l-12 190 -39 57 c-37 53 -90 88
-131 88 -6 0 -100 -39 -207 -86z" />
              <path d="M19866 21485 c-109 -38 -161 -43 -227 -19 -121 43 -141 44 -282 24
-236 -35 -449 -42 -987 -35 -352 5 -519 4 -552 -4 -26 -6 -103 -16 -170 -21
-68 -6 -156 -18 -195 -27 l-73 -17 -82 35 c-118 48 -196 51 -309 11 -46 -17
-113 -42 -149 -58 -95 -40 -185 -38 -300 6 -76 30 -96 33 -185 33 -106 0 -140
-7 -338 -69 -86 -28 -135 -37 -212 -41 -91 -4 -108 -2 -199 26 -54 17 -106 31
-115 31 -9 0 -137 -40 -286 -89 l-270 -89 -341 -22 c-381 -23 -331 -26 -801
40 -417 59 -683 68 -892 30 -57 -10 -74 -8 -308 44 -347 77 -403 86 -529 93
-105 6 -116 4 -220 -27 -148 -45 -242 -48 -531 -20 -229 23 -286 23 -488 1
-206 -23 -503 -32 -1275 -41 -930 -11 -1143 -22 -1495 -80 -203 -34 -216 -39
-243 -87 -14 -26 -32 -93 -47 -172 -13 -72 -28 -137 -33 -147 -14 -25 4 -103
34 -146 115 -165 497 -246 959 -204 423 39 714 93 1004 188 l124 40 436 -12
c448 -13 1052 -9 1546 10 402 16 448 16 630 2 239 -18 330 -30 389 -53 49 -20
82 -21 741 -29 819 -10 1075 -22 1514 -66 140 -14 166 -14 294 1 310 35 743
37 1517 5 211 -9 278 -8 390 4 304 34 624 55 1230 81 442 19 726 34 767 41 26
4 343 -32 349 -39 12 -15 26 -515 29 -1052 4 -678 10 -840 41 -1115 21 -188
14 -542 -20 -1015 -43 -593 -49 -739 -50 -1240 -1 -430 1 -499 16 -563 37
-156 95 -244 193 -293 92 -45 182 -51 258 -16 111 51 186 145 237 294 59 177
62 220 70 898 l7 620 91 345 c51 190 92 358 92 374 0 48 -29 146 -80 267 -106
250 -106 333 -3 491 29 44 56 100 63 130 16 64 9 275 -21 703 -31 429 -31 746
1 975 34 243 35 523 2 655 -57 227 -171 361 -361 422 -25 8 -93 16 -151 19
-101 5 -109 4 -204 -31z" />
              <path d="M5200 21099 c-138 -22 -457 -55 -670 -69 -161 -10 -618 -22 -1630
-41 -327 -6 -641 -14 -698 -16 l-103 -5 -61 -56 c-65 -60 -97 -117 -98 -169 0
-18 41 -139 92 -270 82 -214 96 -243 138 -288 65 -70 74 -89 56 -117 -24 -38
-37 -173 -36 -383 0 -110 -6 -283 -15 -385 -45 -547 -30 -718 95 -1055 l50
-137 -10 -176 c-12 -220 -12 -997 0 -1272 23 -509 23 -771 1 -1162 -29 -514
-27 -631 19 -1439 57 -1006 57 -1039 -41 -2139 -11 -124 -23 -334 -26 -467 -7
-272 -5 -281 62 -303 55 -18 142 27 252 133 66 62 107 92 163 119 41 19 122
66 180 104 58 38 124 77 147 88 47 21 44 11 70 211 14 111 17 231 18 805 1
492 4 709 14 800 16 146 10 660 -14 1270 -24 621 -37 1205 -30 1390 3 96 12
315 20 485 29 628 29 588 1 890 -84 928 -119 1692 -98 2165 l9 205 -44 130
c-64 193 -56 275 34 356 89 81 268 109 472 75 89 -15 111 -16 179 -4 110 19
538 15 787 -6 280 -24 605 -31 738 -17 143 16 282 52 375 98 84 41 183 127
232 201 27 41 30 51 24 96 -23 204 -119 312 -309 350 -118 24 -220 25 -345 5z" />
              <path d="M14745 19884 c-636 -119 -955 -188 -1350 -289 -1570 -400 -2603 -997
-3238 -1868 -101 -138 -169 -246 -260 -413 -36 -67 -91 -156 -122 -199 -120
-168 -234 -398 -310 -629 -48 -143 -95 -343 -95 -399 0 -21 18 -75 45 -133 38
-81 45 -106 45 -158 1 -191 89 -335 253 -411 57 -27 80 -45 113 -88 144 -189
435 -301 944 -366 91 -12 250 -33 353 -47 l188 -26 227 16 c968 70 1837 245
2739 550 370 125 685 250 773 305 122 77 212 124 450 231 381 172 514 245 653
357 l68 55 132 -4 c119 -3 135 -5 165 -26 18 -13 42 -42 55 -65 20 -39 22 -55
21 -212 0 -148 -5 -192 -32 -335 -62 -322 -64 -458 -6 -480 44 -17 142 -11
273 16 148 31 224 35 266 12 17 -9 57 -40 90 -68 33 -28 78 -61 99 -72 68 -35
183 -27 230 17 24 22 26 30 26 112 0 48 -11 189 -25 313 -41 378 -34 665 35
1355 66 660 85 996 58 1044 -41 75 -122 85 -239 30 -145 -69 -230 -133 -558
-422 l-101 -89 -267 -118 c-148 -65 -463 -206 -701 -314 -1311 -595 -1897
-827 -2597 -1029 -321 -93 -699 -182 -724 -171 -9 4 -110 8 -226 9 -169 2
-219 6 -255 19 -38 15 -93 18 -360 20 -336 3 -446 15 -615 66 -238 74 -376
215 -406 413 -10 66 -9 89 10 180 57 285 209 598 414 852 128 159 359 366 563
505 12 8 66 55 120 104 388 350 797 594 1303 775 78 28 219 86 314 130 330
150 443 172 632 124 131 -33 225 -88 474 -276 273 -207 453 -260 711 -211 397
75 681 367 707 723 10 156 -35 270 -149 376 -142 131 -389 214 -688 229 -74 4
-123 0 -225 -20z" />
              <path d="M19435 14975 c-121 -20 -235 -54 -433 -132 -92 -36 -198 -76 -237
-89 -66 -23 -78 -33 -220 -172 -82 -82 -175 -169 -205 -194 -117 -96 -248
-171 -371 -213 -57 -20 -74 -21 -120 -13 -126 23 -239 111 -321 250 -63 109
-213 240 -353 310 -76 39 -182 68 -265 74 -71 6 -84 3 -235 -46 -420 -137
-750 -315 -1237 -667 -125 -90 -181 -140 -275 -240 -138 -147 -194 -193 -224
-186 -38 10 -135 110 -169 174 -30 56 -32 67 -28 129 4 56 15 89 61 187 108
229 112 330 19 426 -42 43 -122 72 -169 61 -85 -20 -133 -65 -188 -174 l-34
-67 -138 -80 -138 -81 -17 -128 c-35 -265 -57 -575 -83 -1144 -19 -422 -32
-634 -50 -830 -15 -152 -14 -155 7 -198 26 -55 95 -102 148 -102 56 0 153 54
224 125 34 34 109 132 166 216 202 299 488 549 972 848 102 64 129 87 211 179
305 346 615 584 944 725 132 57 193 68 241 43 44 -23 59 -50 93 -167 16 -56
36 -111 44 -123 8 -11 15 -28 15 -36 0 -10 31 -31 81 -55 44 -22 85 -46 91
-53 6 -7 33 -22 59 -34 97 -41 157 -133 233 -356 52 -153 75 -200 122 -251 40
-45 83 -68 151 -80 89 -17 177 44 280 194 59 86 87 113 188 182 112 77 230
175 450 373 292 263 440 373 620 460 159 78 295 115 463 126 71 5 115 1 237
-21 275 -50 496 -120 697 -221 l108 -54 299 0 c224 0 317 4 369 15 38 8 139
26 223 40 199 33 325 67 422 112 l77 36 0 67 0 67 -57 47 c-100 80 -196 116
-314 116 -27 0 -115 -15 -196 -32 -131 -29 -166 -33 -298 -33 -174 0 -229 12
-370 79 -107 51 -203 121 -384 280 -195 172 -291 230 -471 282 -134 39 -252
55 -445 59 -129 3 -206 0 -270 -10z" />
              <path d="M19894 13747 c-17 -8 -52 -40 -78 -71 -27 -31 -54 -56 -61 -56 -13 0
-47 -146 -65 -282 -23 -178 -32 -471 -26 -821 l6 -358 60 -380 60 -380 -6
-612 c-7 -601 7 -2266 26 -3232 25 -1275 54 -2442 61 -2450 3 -3 41 -14 85
-25 55 -15 138 -24 272 -31 l193 -10 47 27 c136 80 243 230 293 410 41 149 38
511 -12 1234 -30 441 -35 735 -16 845 27 154 38 350 35 625 -7 645 5 1252 31
1540 11 116 -8 365 -109 1405 -72 748 -92 1122 -91 1655 l1 405 -47 168 c-47
166 -47 168 -91 204 -170 139 -463 237 -568 190z" />
              <path d="M13065 13689 c-182 -52 -269 -94 -350 -170 -48 -45 -75 -82 -105
-141 -22 -44 -40 -87 -40 -94 0 -21 -79 -105 -538 -567 -499 -503 -527 -525
-710 -587 -111 -37 -150 -38 -186 -5 -42 38 -58 90 -64 196 -6 129 12 211 108
499 92 272 110 342 110 426 0 118 -85 224 -181 224 -101 0 -254 -71 -519 -241
l-186 -119 -152 -518 -153 -517 30 -178 c55 -327 113 -487 213 -593 61 -65
114 -98 201 -127 53 -17 92 -21 262 -23 110 -1 208 -3 217 -4 29 -2 190 79
238 119 25 22 88 62 140 91 120 66 292 180 535 353 249 178 433 293 446 280
28 -28 36 -216 15 -361 -49 -328 -66 -654 -72 -1402 l-6 -645 -29 -94 c-71
-226 -89 -461 -81 -1066 6 -468 -2 -595 -58 -945 -49 -309 -76 -514 -145
-1125 -31 -269 -74 -616 -97 -770 -46 -305 -49 -360 -21 -408 84 -146 504 -72
1000 177 82 40 158 82 170 92 19 17 23 38 37 199 43 489 54 790 66 1770 15
1271 28 1710 65 2185 18 218 58 575 79 697 12 64 13 134 6 364 -34 1115 -19
1939 50 2615 22 214 18 267 -27 362 -26 55 -70 92 -108 91 -11 0 -83 -18 -160
-40z" />
              <path d="M16659 13566 c-37 -38 -64 -58 -86 -62 -29 -5 -33 -10 -33 -38 0 -17
-16 -179 -35 -361 -126 -1193 -151 -1499 -170 -2075 -5 -152 -12 -217 -41
-364 -149 -776 -246 -1616 -273 -2371 -17 -443 -18 -1189 -3 -1265 18 -91 56
-165 91 -179 36 -16 98 7 326 121 206 103 303 136 453 151 98 10 115 14 124
32 6 11 22 33 37 48 l26 29 -3 176 c-1 97 -16 335 -32 527 -41 495 -46 631
-26 790 25 194 48 327 142 795 l86 425 -13 195 c-20 321 -6 590 66 1235 71
631 76 694 82 986 l6 277 -36 88 c-41 102 -71 240 -87 399 -6 61 -13 133 -16
161 -4 41 -14 63 -58 120 -60 79 -111 114 -249 170 -62 24 -114 38 -159 42
l-66 5 -53 -57z" />
              <path d="M7060 13041 c-862 -170 -1484 -385 -1970 -681 -74 -45 -229 -125
-345 -177 -451 -204 -902 -434 -1215 -621 -895 -533 -1502 -1143 -1865 -1872
-66 -133 -74 -156 -95 -272 -29 -162 -25 -344 9 -450 120 -373 677 -637 1450
-688 157 -11 167 -10 309 16 324 58 678 162 964 282 121 51 158 62 243 72 375
44 833 151 1237 289 464 159 887 351 1698 773 485 252 675 347 898 452 233
109 471 210 478 203 20 -20 36 -142 40 -312 5 -184 0 -248 -160 -2080 -155
-1769 -174 -1963 -296 -3039 -140 -1237 -139 -1217 -90 -1285 31 -45 72 -56
200 -56 106 0 121 2 195 32 119 49 270 135 545 316 137 89 255 167 264 172 15
9 16 26 5 185 -14 207 -7 752 16 1185 8 160 18 412 21 560 6 267 7 271 39 380
43 143 80 310 102 460 26 179 23 545 -7 820 -31 291 -25 591 31 1525 5 96 13
348 16 560 4 323 2 451 -17 795 -17 318 -21 483 -18 735 l4 325 -39 2 c-21 2
-69 12 -106 23 -38 11 -75 20 -83 20 -18 0 -271 -108 -474 -203 -307 -142
-610 -301 -1344 -701 -443 -242 -986 -518 -1240 -630 -502 -222 -982 -384
-1445 -486 l-190 -42 -345 17 -345 17 -168 -36 c-337 -73 -546 -96 -888 -96
l-236 0 -7 53 c-11 98 -9 113 27 142 17 15 122 109 232 210 418 381 599 516
856 640 122 58 127 62 169 124 57 82 174 200 275 276 195 147 419 256 920 450
381 147 438 173 538 239 46 30 135 80 198 110 351 169 729 222 1114 156 164
-28 301 -36 349 -20 80 26 219 187 299 345 58 117 77 187 77 289 -1 328 -192
518 -535 531 -86 4 -127 -1 -295 -34z" />
              <path d="M2822 8119 c-57 -11 -137 -55 -173 -96 -139 -159 -164 -475 -94
-1208 40 -421 45 -502 47 -686 1 -193 -2 -215 -72 -433 -58 -182 -68 -257 -47
-343 33 -140 39 -211 39 -453 -1 -421 21 -607 89 -741 l31 -61 -17 -305 c-13
-243 -14 -329 -5 -426 6 -67 13 -124 16 -128 11 -18 -20 -206 -61 -366 -77
-300 -90 -413 -60 -543 35 -148 104 -275 182 -335 61 -47 99 -52 186 -27 153
45 312 68 677 101 305 27 1751 58 2000 42 466 -30 990 -47 2030 -66 885 -16
1509 -32 1640 -41 47 -3 402 -44 790 -90 476 -58 723 -84 760 -80 30 3 120 28
200 55 80 28 170 53 200 57 52 6 61 3 137 -36 l81 -43 97 17 c53 9 112 16 131
16 19 0 34 5 34 11 0 8 -313 881 -357 995 -6 17 -146 79 -223 99 -35 9 -102
19 -149 22 -99 6 -494 -11 -763 -32 -101 -9 -277 -18 -393 -21 -207 -6 -216
-5 -650 45 l-440 52 -1080 48 c-960 43 -1088 47 -1157 35 -265 -45 -342 -49
-1113 -49 l-740 0 -155 40 c-135 35 -169 40 -265 40 -98 0 -129 -5 -293 -48
-190 -49 -290 -59 -404 -42 l-45 7 -9 56 c-14 94 -7 304 20 542 53 470 54 514
14 895 -21 208 -21 438 1 665 6 52 15 174 21 270 l11 175 -35 145 c-107 443
-157 921 -186 1785 l-11 310 -55 53 c-110 104 -247 148 -382 121z" />
              <path d="M15560 5819 c-562 -118 -780 -167 -1012 -225 -825 -206 -1338 -431
-1743 -765 -56 -46 -155 -109 -288 -183 -111 -62 -242 -139 -292 -170 -333
-215 -530 -449 -619 -734 -32 -102 -32 -107 -2 -223 35 -135 104 -298 209
-499 50 -96 180 -353 288 -570 264 -528 403 -757 610 -1005 102 -121 112 -127
404 -224 339 -113 679 -190 747 -170 74 22 257 60 498 104 430 79 605 123 774
195 89 38 95 43 238 189 167 171 331 362 461 536 49 66 110 143 135 170 132
147 225 346 332 713 l39 132 -19 73 c-10 40 -41 123 -68 184 l-49 111 -6 153
c-3 86 -12 182 -21 217 -104 417 -412 660 -903 713 -243 26 -476 -31 -848
-208 -482 -229 -604 -266 -930 -282 l-150 -7 -103 -48 c-67 -31 -111 -47 -127
-43 -22 4 -29 -3 -59 -67 -20 -42 -80 -127 -146 -209 -175 -217 -216 -290
-241 -431 -10 -59 -9 -72 11 -131 37 -111 102 -185 247 -281 73 -49 90 -66
157 -163 201 -290 363 -414 608 -463 93 -19 112 -20 188 -9 263 38 500 139
718 308 48 37 150 130 228 207 119 119 146 152 174 211 46 97 116 163 233 219
95 46 226 86 283 86 18 0 55 -11 83 -23 l50 -24 -90 -149 c-363 -596 -611
-891 -924 -1099 l-110 -73 -285 4 c-577 7 -851 40 -1054 124 -88 37 -129 78
-231 230 -207 310 -322 650 -421 1242 l-36 217 21 68 c56 185 208 319 459 403
50 16 166 48 258 71 92 22 193 53 223 69 190 95 566 217 891 289 442 97 1294
214 1565 214 319 1 709 -136 1171 -412 l152 -90 62 -100 c130 -212 314 -434
469 -567 67 -58 91 -85 100 -114 27 -91 133 -210 230 -260 124 -63 289 -84
429 -56 196 40 374 123 625 293 285 193 472 317 505 335 36 19 62 91 62 169 0
44 2 47 31 53 45 9 117 7 244 -4 151 -15 182 -23 315 -85 299 -139 642 -217
879 -201 108 8 201 29 536 121 289 79 483 120 670 139 104 10 175 25 273 54
74 22 135 42 138 44 9 10 15 236 7 273 l-9 40 -69 1 c-39 1 -108 9 -155 19
-111 23 -217 16 -573 -36 -334 -49 -452 -55 -824 -40 l-292 12 -70 40 c-169
97 -450 206 -658 255 -146 35 -378 43 -543 20 -234 -33 -410 -89 -800 -256
-268 -115 -491 -201 -558 -215 -62 -13 -143 56 -483 414 -302 318 -458 453
-643 559 -76 44 -94 60 -128 111 -76 117 -218 236 -373 314 -74 36 -230 93
-365 132 -136 39 -451 105 -495 104 -17 0 -113 -18 -215 -40z m-479 -1855 c78
-23 144 -59 196 -107 55 -51 81 -99 89 -169 5 -47 3 -58 -22 -99 l-29 -46 -95
-11 c-211 -25 -378 -95 -695 -294 -239 -150 -320 -194 -442 -242 l-93 -37 -43
19 c-24 11 -82 56 -129 100 -47 44 -104 90 -126 101 -44 23 -105 27 -147 11
l-26 -10 32 40 c136 168 455 347 914 509 50 18 185 81 300 141 116 60 222 109
236 110 14 0 50 -7 80 -16z" />
              <path d="M20380 3675 c-104 -33 -161 -116 -220 -320 -43 -146 -90 -243 -140
-289 l-40 -36 -303 0 c-646 0 -1193 -35 -1697 -108 -108 -16 -151 -17 -320 -9
-214 10 -511 -3 -665 -28 -96 -15 -251 -57 -291 -78 -15 -8 -67 -83 -130 -187
-209 -346 -250 -470 -207 -624 31 -112 112 -166 247 -166 64 0 422 37 1046
109 759 88 1098 121 1570 152 96 7 399 13 672 14 l498 2 89 33 c49 17 92 39
96 48 13 35 44 195 59 307 47 357 58 429 81 523 27 113 30 181 15 345 -16 169
-57 255 -144 299 -52 27 -155 33 -216 13z" />
            </g>
          </svg>
        </a>
        <a href={'https://www.instagram.com/kgtrends_/'} target={'_blank'}>
          <FontAwesomeIcon icon={faInstagramSquare} />
        </a>
      </div>
    </div>
  )
}

export default Socials;
