import React from 'react';
import './index.scss';
import Header from "./components/Header";
import StripedContainer from "./components/StripedContainer";
import Divider from "./components/Divider";
import Socials from "./components/Socials";
import * as ReactDOM from "react-dom";
import Videos from "./components/Videos";

function Site() {
  return (
    <>
      <Header />

      <StripedContainer>
        <h1>Valentijnsbrunch</h1>

        <h2>Ook zin in een heerlijke ontbijt?</h2>
        <p>Februari komt er aan..&nbsp;&nbsp; Dus hoog tijd voor onze jaarlijkse Valentijnsbrunch!</p><br />
        <p>
          Net als jullie keken wij ook uit naar een gezellige brunch met fijne optredens.&nbsp;
          <br className={'not-on-mobile'} />
          Maar de maatregelen dwongen ons een alternatief te vinden, minstens even lekker!
          <br />
          <br className={'mobile-only'} />
          Daarom kunnen jullie op <b>13 februari</b> genieten van onze <b>heerlijke ontbijtmanden</b>!
        </p>


        <Divider />

        <p>Onze dansers hebben uiteraard ook iets voor jullie in petto.<br />
          Via opgenomen filmpjes uit de les voorzien ze een <b>virtueel optreden</b>.</p>
        <br className={'mobile-only'} />
        <p>Al deze filmpjes kan je hier online bekijken:</p>
        <br className={'mobile-only'} />
        <br className={'mobile-only'} />

        <Videos />

        <br />

      </StripedContainer>

      <Socials />
    </>
  );
}

ReactDOM.render(<Site />, document.getElementById('root'));
